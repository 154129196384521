<template>
  <div class="form-steps" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="head">
        <div class="title">단계 편집</div>
      </div>
      <div class="body">
        <ArchiveMultiSelect role="step" :items="state.steps"/>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import ArchiveMultiSelect from "@/components/ArchiveMultiSelect.vue";

function Component(initialize) {
  this.name = "modalArchiveSteps";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ArchiveMultiSelect},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      const args = {
        page: 0,
        size: 100
      };

      http.get(`/api/admin/archives/${modalParams.archiveId}/steps`, args).then(({data}) => {
        state.steps = data.body.content;
      });
    });

    const state = reactive({
      steps: []
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      http.put(`/api/admin/archives/${modalParams.archiveId}/steps`, state.steps).then(() => {
        store.commit("setSwingMessage", "폼 단계를 저장하였습니다.");
        store.commit("closeModal", {name: component.name,});
      });
    };

    return {component, state, modalParams, submit,};
  }
});
</script>

<style lang="scss" scoped>
.form-steps {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }
  }

  .body {
    > select {
      background-color: #fff;
      font-size: $px13;
      transition: none;
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
  }
}
</style>